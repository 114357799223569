import * as React from 'react';

import { graphql } from 'gatsby';

import CampaignDetailsModule from '../../modules/campaignDetails';
import { Typography } from '@mui/material';

const CampaignDetailsPage = (props: any) => {
  return (
    <CampaignDetailsModule campaignKey="civilian-2023" showDonationDescription={false}>
      <Typography paragraph={true}>Dear visitor,</Typography>

      <Typography paragraph={true}>
        Unfortunately, the war in Ukraine is still ongoing. We at BI have decided to take an active position and support our colleagues,
        relatives, friends, and all Ukrainians whose lives and destinies are still impacted by this war as much as we can.
      </Typography>

      <Typography paragraph={true}>
        Many people are forced to relocate within Ukraine or are facing the harsh reality of living close to or directly on the frontline
        with a scarcity of basic humanitarian goods and medicaments. And these are the people who need our support at the moment at most.
      </Typography>

      <Typography paragraph={true}>
        Our friends who are based in Kyiv contacted us recently to support them in collecting scarce goods which will be then delivered
        directly to the civilians on-site in Eastern Ukraine (Kharkiv & Donetsk area).
      </Typography>

      <Typography paragraph={true}>
        You can either bring any listed goods below by yourself or can donate any amount of money for the right cause, so we purchase them
        and pass it for further delivery.
      </Typography>

      <Typography paragraph={true}>
        The purchased goods and supplies will be delivered afterwards by our persons of trust directly to the place where they are the most
        needed now. As always, we will provide a final (photo-) report about the collected donations and delivery status.
      </Typography>

      <Typography paragraph={true}>
        For any questions, please contact Sergej Andrushchenko (BI X) via MS Teams or email at{' '}
        <a href="mailto:sergej@ingelheim.mobi">sergej@ingelheim.mobi</a>.
      </Typography>

      <Typography paragraph={true}>
        Most urgently needed goods now are:
        <ul>
          <li>Rescue blankets</li>
          <li>Decompression needles</li>
          <li>Medical tourniquets</li>
          <li>Reinforced tape in a roll</li>
          <li>Nasopharyngeal Airway 28 Fr. or 30 Fr.</li>
          <li>Bandages & dressings (Paramedic) with one cushion</li>
          <li>Pill packs</li>
          <li>Sterile gauze napkin, package 16 x 14 cm</li>
          <li>Sterile gauze bandage 7 m x 14 cm</li>
          <li>Bandage hemostatic Medcloth 7.5 cm X 1.5 m</li>
          <li>Bandage occlusive Combo Pack Paramedic</li>
          <li>Napkins with furagin</li>
          <li>Tactical scissors</li>
          <li>Tactical glasses</li>
          <li>Nitrile gloves</li>
          <li>Powerbanks</li>
          <li>Wet wipes</li>
          <li>Vitamins</li>
          <li>Canned food</li>
          <li>Disposable tableware</li>
          <li>Coffee, tea, chocolate, energy bars</li>
        </ul>
      </Typography>

      <Typography paragraph={true}>
        Bank account for donations:<br/>
        Sergej Andrushchenko<br/>
        DE46 1203 0000 1009 1838 13<br/>
        Purpose: “Help for civilians in Ukraine”
      </Typography>

      <Typography paragraph={true}>
        We stay strong together.
        Thanks a lot in advance for your active position and support!
      </Typography>

    </CampaignDetailsModule>
  );
};

export default CampaignDetailsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
